import React, { useState } from "react";
import { Modal } from "antd";
import styled from "@/theme";
import {
  ConsultantMemoResDto,
  getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory,
  getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory,
  getAdminReservationWaitingReservationConsultingReservationIdMemoHistory,
} from "@/api";

import useAsyncEffect from "@/utils/useAsyncEffect";

// Types
interface MemoHistoryModalProps {
  type: string;
  id?: number;
  showModal: boolean;
  onClose: () => void;
}

// Component
const MemoHistoryModal: React.FC<MemoHistoryModalProps> = ({
  type,
  id,
  showModal,
  onClose,
}) => {
  const [data, setData] = useState<ConsultantMemoResDto[] | undefined | null>(
    undefined
  );

  useAsyncEffect(async () => {
    const getCall =
      type === "예약 대기중"
        ? getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
        : type === "변경/취소 내역"
          ? getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
          : getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory;

    const data = await getCall(id ?? 0);
    setData(data?.result);
  }, [showModal, type, id]);

  return (
    <StyledModal
      centered
      open={showModal}
      onCancel={onClose}
      footer={null}
      width={1100}
    >
      <ModalTitle>이력 관리</ModalTitle>
      <TableHeader>
        <HeaderCell style={{ width: "150px" }}>작성자</HeaderCell>
        <HeaderCell style={{ flex: 1 }}>내용</HeaderCell>
        <HeaderCell style={{ width: "180px" }}>날짜</HeaderCell>
      </TableHeader>
      <TableBody>
        {data?.map((item, index) => (
          <TableRow key={index}>
            <Cell style={{ width: "150px" }}>{item.consultantName}</Cell>
            <Cell style={{ flex: 1 }}>{item.memo}</Cell>
            <Cell style={{ width: "180px" }}>
              {new Date(item.writtenDtm).toLocaleString("ko-KR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Cell>
          </TableRow>
        ))}
      </TableBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
  }
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
`;

const TableHeader = styled.div`
  display: flex;
  background-color: #f8f9fa;
  padding: 12px 16px;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
`;

const TableBody = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const TableRow = styled.div`
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #e9ecef;
`;

const HeaderCell = styled.div`
  font-weight: 600;
  color: #495057;
`;

const Cell = styled.div`
  color: #495057;
`;

export default MemoHistoryModal;
