import {
  ConsultingOptionsReqDto,
  ReservationChangedResDto,
  ReservationConfirmedResDto,
  ReservationWaitingResDto,
  useDeleteAdminReservationConsultingReservationIdCancel,
  useGetAdminReservationConsultantInfo,
  usePostAdminReservationConsultingReservationIdChange,
} from "@/api";
import formatReservationDate from "@/utils/formatReservationDate";
import { Button, Select, DatePicker, Modal, TimePicker } from "antd";
import { TableProps } from "antd/es/table";
import { useState } from "react";
import ConsultingApprovalModal from "@/components/ConsultingApprovalModal";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import Tag from "@/components/Tag";
import styled from "@/theme";
import StatusModal from "./StatusModal";
import { AnyObject } from "antd/es/_util/type";
import toast from "react-hot-toast";
import { useImmer } from "use-immer";
import dayjs from "dayjs";
import { decombineDateTime } from "@/utils/decombineDateTime";
import { combineDateAndTime } from "@/utils/combineDateAndTime";

type UseReservationColumnsOptions = {
  type: "waiting" | "confirmed" | "changed";
  data?:
    | ReservationConfirmedResDto[]
    | ReservationWaitingResDto[]
    | ReservationChangedResDto[];
  isInvestmentSolicitorAdmin: boolean;
  refetch: () => void;
  onClickDetail: (reservationId: number) => void;
  onApproveConsulting: (id: number, params: ConsultingOptionsReqDto) => void;
};

// 상수
const CONSULTING_TYPE_COLORS = {
  대면상담: "positive",
  유선상담: "sub03",
  화상상담: "sub05",
} as const;

const useReservationColumns = ({
  data,
  type,
  isInvestmentSolicitorAdmin,
  refetch,
  onClickDetail,
  onApproveConsulting,
}: UseReservationColumnsOptions) => {
  const [isChanging, setIsChanging] = useState<boolean>(false);

  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [selectedReservationId, setSelectedReservationId] = useState<
    number | null
  >(null);

  const [state, setState] = useImmer({
    consultantName: null as string | null,
    consultingStartDate: null as Date | null,
    consultingStartTime: null as string | null,
  });

  const { data: consultantInfo } = useGetAdminReservationConsultantInfo();
  const { mutateAsync: changeReservation } =
    usePostAdminReservationConsultingReservationIdChange({
      mutation: {
        onSuccess: () => {
          toast.success("예약 변경 완료");
          refetch();
        },
        onError: () => {
          toast.error("문제가 발생하였습니다. 관리자에게 문의해주세요.");
        },
      },
    });

  const { mutateAsync: cancelReservation } =
    useDeleteAdminReservationConsultingReservationIdCancel({
      mutation: {
        onSuccess: () => {
          toast.success("예약 취소 완료");
          refetch();
        },
        onError: () => {
          toast.error("문제가 발생하였습니다. 관리자에게 문의해주세요.");
        },
      },
    });

  // 핵심 로직 함수화
  const handleReservationChange = async () => {
    if (
      !selectedReservationId ||
      !state.consultingStartDate ||
      !state.consultingStartTime
    )
      return;

    const consultantId = consultantInfo?.result?.options?.find(
      (option) => option.consultantName === state.consultantName
    )?.consultantId;

    const combinedDateAndTime = combineDateAndTime(
      state.consultingStartDate,
      state.consultingStartTime
    );

    console.log(
      "combinedDateAndTime",
      combinedDateAndTime.toISOString().replace("Z", "+09:00")
    );

    await changeReservation({
      consultingReservationId: selectedReservationId,
      data: {
        consultantId,
        consultingStartDtm: combinedDateAndTime
          .toISOString()
          .replace("Z", "+09:00"),
      },
    });
  };

  // 데이터 변환 로직
  const transformDataSource = (
    data?: ReservationConfirmedResDto[] | ReservationWaitingResDto[]
  ) => {
    return data?.map((obj, index) => ({
      ...obj,
      key: index,
      actions: {
        consultingReservationId: obj.consultingReservationId,
        consultingTypeCode: obj.consultingTypeCode,
        isConsultationAvailable:
          "isConsultationAvailable" in obj
            ? obj.isConsultationAvailable
            : false,
      },
    }));
  };

  // 렌더링 컴포넌트
  const ConsultantNameCell = ({
    consultantName,
    reservationId,
  }: {
    consultantName: string;
    reservationId: number;
  }) => {
    if (isChanging && reservationId === selectedReservationId) {
      return (
        <Select
          options={consultantInfo?.result?.options?.map((option) => ({
            label: option.consultantName,
            value: option.consultantName,
          }))}
          value={state.consultantName}
          onChange={(value) => {
            setState((draft) => {
              draft.consultantName = value;
            });
          }}
        />
      );
    }
    console.log("consultantName", consultantName);
    if (consultantName === null) return "미정";
    return consultantName;
  };

  // 날짜 셀 컴포넌트 추가
  const DateCell = ({
    date,
    reservationId,
  }: {
    date: string;
    reservationId: number;
  }) => {
    if (isChanging && reservationId === selectedReservationId) {
      return (
        <DatePicker
          value={
            state.consultingStartDate ? dayjs(state.consultingStartDate) : null
          }
          onChange={(value) => {
            setState((dr) => {
              dr.consultingStartDate = value ? value.toDate() : null;
            });
          }}
        />
      );
    }
    return formatReservationDate(date, { dateOnly: true });
  };

  // 시간 선택을 위한 새로운 컴포넌트
  const TimeCell = ({
    date,
    reservationId,
  }: {
    date: string;
    reservationId: number;
  }) => {
    if (isChanging && reservationId === selectedReservationId) {
      return (
        <TimePicker
          format="HH:mm"
          minuteStep={30}
          value={
            state.consultingStartTime
              ? dayjs(state.consultingStartTime, "HH:mm")
              : null
          }
          onChange={(value) => {
            setState((draft) => {
              draft.consultingStartTime = value ? value.format("HH:mm") : null;
            });
          }}
          disabledTime={() => ({
            disabledHours: () => [
              0, 1, 2, 3, 4, 5, 6, 7, 8, 20, 21, 22, 23, 24,
            ],
            disabledMinutes: () =>
              Array(60)
                .fill(0)
                .map((_, index) => index)
                .filter((minute) => minute % 30 !== 0), // 30분 단위로만 선택 가능
          })}
        />
      );
    }
    return formatReservationDate(date, { timeOnly: true });
  };

  // 컬럼 설정
  const getBaseColumns = () => [
    {
      title: "고객명",
      dataIndex: "crmCustomerName",
      key: "crmCustomerName",
    },
    {
      title: "상담유형",
      dataIndex: "consultingTypeCode",
      key: "consultingTypeCode",
      render: (consultingTypeCode: string) => (
        <TagWrapper>
          <Tag
            color={
              CONSULTING_TYPE_COLORS[
                consultingTypeCode as keyof typeof CONSULTING_TYPE_COLORS
              ]
            }
            text={consultingTypeCode}
          />
        </TagWrapper>
      ),
    },
  ];

  const getTypeSpecificColumns = () => {
    if (
      isInvestmentSolicitorAdmin &&
      (type === "confirmed" || type === "changed")
    ) {
      return [
        {
          title: "상담사",
          dataIndex: "consultantName",
          key: "consultantName",
          render: (consultantName: string, record: any) => (
            <ConsultantNameCell
              consultantName={consultantName}
              reservationId={record.consultingReservationId}
            />
          ),
        },
      ];
    }
    if (type === "waiting") {
      return [
        {
          title: "접수 경과일수",
          dataIndex: "reservationRequestedDtm",
          key: "reservationRequestedDtm",
          render: (date: string) => {
            const diffDays = Math.floor(
              (new Date().getTime() - new Date(date).getTime()) /
                (1000 * 60 * 60 * 24)
            );
            return `D+${diffDays}`;
          },
        },
      ];
    }
    return [];
  };

  const handleActionClick = (
    reservationId: number,
    action: "approval" | "status" | "change"
  ) => {
    if (action === "approval") {
      setShowApprovalModal(true);
    } else if (action === "change") {
      const reservation = data?.find(
        (item) => item.consultingReservationId === reservationId
      );

      if (!reservation || !("consultantName" in reservation)) return;

      const { date, time } = decombineDateTime(
        new Date(reservation.consultingStartDtm)
      );

      console.log('date.toISOString().replace("Z", "+09:00")', date, time);
      setState((dr) => {
        dr.consultantName = reservation.consultantName;
        dr.consultingStartDate = date;
        dr.consultingStartTime = time;
      });
      setIsChanging(true);
    } else {
      setShowStatusModal(true);
    }
  };

  const showCancelConfirm = () => {
    Modal.confirm({
      title: "예약 취소",
      content: "정말 예약을 취소하시겠습니까?",
      okText: "예",
      cancelText: "아니오",
      okButtonProps: { danger: true },
      onOk: async () => {
        if (!selectedReservationId) return;

        await cancelReservation({
          consultingReservationId: selectedReservationId,
        });
        setIsChanging(false);
      },
    });
  };

  const renderActions = (record: AnyObject) => {
    return (
      <ButtonWrapper>
        {isChanging ? (
          <>
            <Button
              type="primary"
              onClick={() => {
                handleReservationChange();
                setIsChanging(false);
              }}
            >
              변경 완료
            </Button>
            <Button type="primary" danger onClick={showCancelConfirm}>
              예약 취소
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() =>
                onClickDetail(record.actions.consultingReservationId)
              }
            >
              고객 정보
            </Button>
            {type !== "changed" && (
              <Button
                type="primary"
                disabled={
                  type === "waiting" && !record.actions.isConsultationAvailable
                }
                onClick={() => {
                  setSelectedReservationId(
                    record.actions.consultingReservationId
                  );

                  handleActionClick(
                    record.actions.consultingReservationId,
                    type === "waiting"
                      ? "approval"
                      : isInvestmentSolicitorAdmin
                        ? "change"
                        : "status"
                  );
                }}
              >
                {type === "waiting"
                  ? "상담 승인"
                  : type === "confirmed"
                    ? isInvestmentSolicitorAdmin
                      ? "예약 변경"
                      : "상태 설정"
                    : null}
              </Button>
            )}
          </>
        )}
      </ButtonWrapper>
    );
  };

  const columns: TableProps["columns"] = [
    ...getBaseColumns(),
    ...getTypeSpecificColumns(),
    {
      title: "예약일자",
      dataIndex: "consultingStartDtm",
      key: "consultingStartDtm",
      render: (date: string, record: any) => {
        if (type === "waiting") {
          return formatReservationDate(date);
        }
        if (type === "changed") {
          return (
            <Opacity>{formatReservationDate(date, { dateOnly: true })}</Opacity>
          );
        }
        return (
          <DateCell
            date={date}
            reservationId={record.consultingReservationId}
          />
        );
      },
    },
    ...(type === "confirmed" || type === "changed"
      ? [
          {
            title: "예약시간",
            dataIndex: "consultingStartDtm",
            key: "consultingStartDtm",
            render: (date: string, record: any) => {
              if (type === "changed") {
                return (
                  <Opacity>
                    {formatReservationDate(date, { timeOnly: true })}
                  </Opacity>
                );
              }
              return (
                <TimeCell
                  date={date}
                  reservationId={record.consultingReservationId}
                />
              );
            },
          },
          {
            title: "고객 연락처",
            dataIndex: "crmCustomerPhoneNum",
            key: "crmCustomerPhoneNum",
            render: (phoneNum: string) => formatPhoneNumber(phoneNum),
          },
        ]
      : []),

    ...(type === "changed"
      ? [
          {
            title: "사유",
            dataIndex: "consultingStatusCode",
            key: "consultingStatusCode",
            render: (consultingStatusCode: string) => consultingStatusCode,
          },
        ]
      : []),

    {
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        if (
          isChanging
            ? record.consultingReservationId === selectedReservationId
            : true
        ) {
          return renderActions(record);
        }
      },
    },
  ];

  return {
    columns,
    dataSource: transformDataSource(data),
    ApprovalModal: selectedReservationId && (
      <ConsultingApprovalModal
        isOpen={showApprovalModal}
        onClose={() => setShowApprovalModal(false)}
        onConfirm={(params) =>
          onApproveConsulting(selectedReservationId, params)
        }
      />
    ),
    StatusModal: selectedReservationId && (
      <StatusModal
        showModal={showStatusModal}
        onClose={() => setShowStatusModal(false)}
        consultingReservationId={selectedReservationId}
      />
    ),
  };
};

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const Opacity = styled.div`
  opacity: 0.5;
`;

export default useReservationColumns;
