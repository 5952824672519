import { Form, Input, Button, Select } from "antd";
import {
  UserOutlined,
  PhoneOutlined,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import styled from "@/theme";
import { CognitoSignUpModel, usePostAdminUserSignUp } from "@/api";
import getErrorMessageFromRes from "@/utils/getErrorMessageFromRes";
import ErrorMessage from "@/components/ErrorMessage";
import LoadingOverlay from "@/components/LoadingOverlay";

type RegisterRequestFormProps = {
  onSuccess?: () => void;
};
type RegisterRequestFormState = CognitoSignUpModel;

export const ServiceKindCode = {
  프리즘어드민: "프리즘어드민",
  CRM어드민: "CRM어드민",
} as const;

function RegisterRequestForm({ onSuccess }: RegisterRequestFormProps) {
  const { mutateAsync: signUp, isPending, error } = usePostAdminUserSignUp();

  const onFinish = async (data: RegisterRequestFormState) => {
    const { ...args } = data;

    const res = await signUp({ data: args });
    if (res.result) {
      onSuccess?.();
    }
  };

  const errorMessage = getErrorMessageFromRes(error);

  return (
    <Container>
      <Form name="normal_login" className="login-form" onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "이메일을 입력해 주세요." }]}
        >
          <Input
            prefix={<MailOutlined className="site-form-item-icon" />}
            placeholder="이메일"
          />
        </Form.Item>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "이름을 입력해 주세요." }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="이름"
          />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[{ required: true, message: "전화번호를 입력해 주세요." }]}
        >
          <Input
            prefix={<PhoneOutlined className="site-form-item-icon" />}
            placeholder="전화번호"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력해 주세요." }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="비밀번호"
          />
        </Form.Item>
        <Form.Item
          name="passwordCheck"
          rules={[
            {
              required: true,
              message: "비밀번호를 한번 더 입력해 주세요.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("비밀번호가 일치하지 않습니다.")
                );
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="비밀번호 확인"
          />
        </Form.Item>
        <Form.Item
          name="serviceKindCode"
          rules={[{ required: true, message: "서비스 종류를 선택해 주세요." }]}
        >
          <Select placeholder="서비스 종류">
            {Object.entries(ServiceKindCode).map(([key, value]) => (
              <Select.Option key={value} value={value}>
                {key}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <RegisterButton
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            회원가입
          </RegisterButton>
        </Form.Item>
      </Form>

      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {isPending && <LoadingOverlay />}
    </Container>
  );
}

const Container = styled.div`
  width: 300px;

  margin-top: 30px;
`;

const RegisterButton = styled(Button)`
  width: 100%;
`;

export default RegisterRequestForm;
