import React, { useState } from "react";
import { Modal } from "antd";
import styled from "@/theme";
import Icon from "@ant-design/icons";
import {
  CrmCustomerInfoResDto,
  getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo,
  getAdminReservationConfirmedReservationConsultingReservationIdInfo,
  getAdminReservationWaitingReservationConsultingReservationIdInfo,
  postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo,
  postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo,
  postAdminReservationWaitingReservationConsultingReservationIdSaveMemo,
} from "@/api";
import { formatPhoneNumber } from "@/utils/formatPhoneNumber";
import Tag from "@/components/Tag";
import Space from "@/components/Space";
import formatReservationDate from "@/utils/formatReservationDate";
import { TextAreaProps } from "antd/es/input";
import useAsyncEffect from "@/utils/useAsyncEffect";
import { toast } from "react-hot-toast";
import MemoHistoryModal from "./MemoHistoryModal";
import tokens from "@/tokens";

const SURVEY_DATA = {
  appExperience: [
    "프리즘으로 자문계약 투자중이에요",
    "앱은 설치했지만 투자는 하고 있지 않아요",
    "앱 설치 및 사용 경험이 없어요",
  ],
  mainConcern: [
    "퇴직금 불리기",
    "은퇴 후 자산관리",
    "금융소득종합과세 걱정",
    "법인 투자 희망",
    "자녀 증여/상속 투자",
    "노후/연금 준비",
    "단기 자금 준비(3년 이하)",
    "중장기 자금 준비(5년 이상)",
    "보유 중인 보험 점검",
    "보유 중인 연금 점검",
    "프리즘에서 내 투자 관리 희망",
    "금융자산 외 소득공제에 관심",
    "비과세 또는 분리과세 자산에 흥미",
  ],
  incomeSource: ["급여 소득", "사업 소득", "그 외(연금/배당/임대 등)"],
  financialIncomeTax: ["있음", "없음", "잘 모르겠어요"],
} as const;

// Types
interface ResModalProps {
  type: string;
  id?: number;
  showModal: boolean;
  onClose: () => void;
}

interface TextBoxProps {
  noData?: boolean;
}

// Component
const ResModal: React.FC<ResModalProps> = ({
  type,
  id,
  showModal,
  onClose,
}) => {
  if (!showModal) return null;

  const [data, setData] = useState<CrmCustomerInfoResDto | undefined>(
    undefined
  );
  const [memo, setMemo] = useState("");
  const [showMemoHistoryModal, setShowMemoHistoryModal] = useState(false);

  const callApi = async () => {
    const getCall =
      type === "예약 대기중"
        ? getAdminReservationWaitingReservationConsultingReservationIdInfo
        : type === "변경/취소 내역"
          ? getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
          : getAdminReservationConfirmedReservationConsultingReservationIdInfo;

    const data = await getCall(id ?? 0);
    setData(data?.result);
    setMemo(data?.result?.memo ?? "");
  };

  useAsyncEffect(async () => {
    await callApi();
  }, [type, id]);

  const surveyAnswers = {
    appExperience: data?.surveyAnswerList[0].numAnswer!,
    mainConcern: data?.surveyAnswerList[1].numArrAnswer,
    mainConcernDetail: data?.surveyAnswerList[2].strAnswer,
    annualIncome: data?.surveyAnswerList[3].strAnswer,
    incomeSource: data?.surveyAnswerList[4].numArrAnswer,
    financialIncomeTax: data?.surveyAnswerList[5].numAnswer!,
    monthlyInvestment: data?.surveyAnswerList[6].strAnswer,
    assets: {
      pensionISAIRP: data?.surveyAnswerList[7].strAnswer,
      stock: data?.surveyAnswerList[8].strAnswer,
      bond: data?.surveyAnswerList[9].strAnswer,
      cash: data?.surveyAnswerList[10].strAnswer,
      insurance: data?.surveyAnswerList[11].strAnswer,
    },
  };

  const formatAmount = (amount: string | null) =>
    amount ? `${Number(amount).toLocaleString()} 만원` : "없음";

  const CUSTOMER_INFO = [
    {
      title: "금소세 납부이력",
      value: SURVEY_DATA.financialIncomeTax[surveyAnswers.financialIncomeTax],
    },
    {
      title: "연소득",
      value: formatAmount(surveyAnswers.annualIncome ?? "0"),
    },
    {
      title: "소득 출처",
      values: surveyAnswers.incomeSource?.map(
        (item) => SURVEY_DATA.incomeSource[item]
      ),
    },
    {
      title: "월 투자가능액",
      value: formatAmount(surveyAnswers.monthlyInvestment ?? "0"),
    },
  ];

  const ASSET_INFO = [
    {
      title: "연금저축/ISA/IRP 자산",
      value: surveyAnswers.assets.pensionISAIRP,
    },
    { title: "주식형 자산", value: surveyAnswers.assets.stock },
    { title: "채권형 자산", value: surveyAnswers.assets.bond },
    { title: "현금성 자산", value: surveyAnswers.assets.cash },
    { title: "보험 자산", value: surveyAnswers.assets.insurance },
  ].map(({ title, value }) => ({
    title,
    value: formatAmount(value ?? "0"),
    noData: value === null,
  }));

  const handleSave = async () => {
    console.log("save");
    const postCall =
      type === "예약 대기중"
        ? postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
        : type === "변경/취소 내역"
          ? postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
          : postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo;

    await postCall(id ?? 0, {
      memo,
      lastMemoWrittenUdtm: data?.lastMemoWrittenUdtm,
    });

    await callApi();

    toast.success("메모가 저장되었습니다.");
  };

  console.log("reservationData", data);

  return (
    <StyledModal
      centered
      open={showModal}
      onCancel={onClose}
      footer={null}
      width={1100}
    >
      <Section>
        <Row>
          <Name>{data?.crmCustomerName}</Name>
          <DividerLight />
          <Age>{data?.crmCustomerAge}세</Age>
          <DividerLight />
          <Icon name="phone" width={16} height={16} />
          <Phone>{formatPhoneNumber(data?.crmCustomerPhoneNumber)}</Phone>
        </Row>
        <Space h={12} />
        <Row>
          <Tag
            color={
              data?.consultingTypeCode === "대면상담"
                ? "positive"
                : data?.consultingTypeCode === "유선상담"
                  ? "sub03"
                  : "sub05"
            }
            text={data?.consultingTypeCode ?? ""}
          />
          <Space w={10} />
          <Tag color={"sub01"} text={data?.consultingStatusCode ?? ""} />
          <Space w={10} />
          <DateText>{formatReservationDate(data?.consultingStartDtm)}</DateText>
          <Space w={10} />
          {data?.consultantName && (
            <ConsultantName>{data?.consultantName} 상담</ConsultantName>
          )}
        </Row>
        <Space h={12} />
        <Row justify="space-between">
          <Address>{data?.consultingAddress}</Address>
          <RequestDate>
            {`${formatReservationDate(data?.reservationRequestedDtm)} 접수`}
          </RequestDate>
        </Row>
      </Section>

      <Divider />

      <Container>
        <InnerContainer>
          <Section>
            <Title>프리즘 앱 사용 경험</Title>
            <TextBoxContainer>
              <TextBox>
                {SURVEY_DATA.appExperience[surveyAnswers.appExperience]}
              </TextBox>
            </TextBoxContainer>
          </Section>

          <Section>
            <Title>주요 고민</Title>
            <TextBoxContainer>
              {surveyAnswers.mainConcern?.map((item) => (
                <TextBox key={item}>{SURVEY_DATA.mainConcern[item]}</TextBox>
              ))}
            </TextBoxContainer>
          </Section>

          <Section>
            <Title>주요 고민 상세</Title>
            <TextBoxContainer>
              <TextBox>{surveyAnswers.mainConcernDetail}</TextBox>
            </TextBoxContainer>
          </Section>

          <Divider />

          {CUSTOMER_INFO.map(({ title, value, values }) => (
            <Row2 key={title}>
              <Title>{title}</Title>
              {values ? (
                <TextBoxContainer>
                  {values.map((v) => (
                    <TextBox key={v}>{v}</TextBox>
                  ))}
                </TextBoxContainer>
              ) : (
                <TextBox>{value}</TextBox>
              )}
            </Row2>
          ))}

          <Divider />

          {ASSET_INFO.map(({ title, value, noData }) => (
            <Row2 key={title}>
              <Title>{title}</Title>
              <TextBox noData={noData}>{noData ? "없음" : value}</TextBox>
            </Row2>
          ))}
        </InnerContainer>
        <MemoContainer>
          <MemoHeader>
            <Title>상담 메모</Title>
            <List onClick={() => setShowMemoHistoryModal(true)}>이력 관리</List>
          </MemoHeader>
          <TextArea
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder="내용을 입력해주세요."
            disabled={
              tokens.isInvestmentSolicitorAdmin
                ? false
                : !(
                    data?.consultingStatusCode === "예약확정" ||
                    data?.consultingStatusCode === "상담완료"
                  )
            }
          />
          <SaveButton onClick={handleSave}>저장</SaveButton>
        </MemoContainer>
      </Container>

      <MemoHistoryModal
        type={type}
        id={id}
        showModal={showMemoHistoryModal}
        onClose={() => setShowMemoHistoryModal(false)}
      />
    </StyledModal>
  );
};

const MemoHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const List = styled.div`
  color: var(--Sub01-Sub01-400, #476cec);
  /* Paragraph 03/Semibold */
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */

  cursor: pointer;
`;

const RequestDate = styled.div`
  color: var(--Gray-Gray-400, #7a8190);
  /* Paragraph 01/Regular */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const ConsultantName = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;

const SaveButton = styled.button`
  background-color: ${(p) => p.theme.colors.primary400};
  color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
  padding: 13px 0;
  width: 150px;

  margin-top: 8px;

  align-self: flex-end;
  cursor: pointer;
`;

const Address = styled.div`
  color: var(--Shades-Black, #000);

  /* Paragraph 01/Semibold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 24px;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${(p) => p.theme.colors.gray500};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 8px;
`;

const TextBox = styled.div<TextBoxProps>`
  display: flex;
  padding: 4px 8px;
  white-space: nowrap;

  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: ${(p) => (p.noData ? p.theme.colors.gray300 : p.theme.colors.gray700)};
  background-color: ${(p) =>
    p.noData ? p.theme.colors.gray100 : p.theme.colors.gray50};
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid ${(p) => p.theme.colors.gray200};
  margin: 16px 0;
`;

const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  height: 100%;
  padding: 14px 16px;
  border: 1px solid ${(p) => p.theme.colors.gray100};
  border-radius: 12px;
  background-color: ${(p) => p.theme.colors.white};
  resize: none;

  color: var(--Gray-Gray-400, #7a8190);
  /* Paragraph 02/Medium */
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 146.667% */

  &::placeholder {
    color: ${(p) => p.theme.colors.gray300};
  }

  &:disabled {
    background-color: ${(p) => p.theme.colors.gray100};
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MemoContainer = styled(InnerContainer)``;

const Row = styled.div<{ justify?: "space-between" }>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => p.justify ?? "flex-start"};
`;

const Row2 = styled(Row)`
  justify-content: space-between;
  margin-top: 20px;
`;

const Text = styled.div`
  font-family: Pretendard;
  font-weight: 600;
`;

const Name = styled(Text)`
  font-size: 20px;
  line-height: 32px;
  color: ${(p) => p.theme.colors.black};
`;

const Age = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.black};
`;

const Phone = styled(Age)`
  margin-left: 6px;
`;

const DateText = styled(Text)`
  font-size: 16px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.gray500};
`;

const DividerLight = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${(p) => p.theme.colors.gray400};
  margin: 0 10px;
`;

const TextBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default ResModal;
