/**
 * Do not edit manually.
 * Frism Admin Server
 * OpenAPI spec version: admin
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  ApiResponse,
  ConsultantInfoResDtoConsultingOptionsResDtoApiResponse,
  ConsultantMemoResDtoListApiResponse,
  ConsultingAddressResDtoConsultingOptionsResDtoApiResponse,
  ConsultingContactInfoResDtoConsultingOptionsResDtoApiResponse,
  ConsultingOptionsReqDto,
  CrmCustomerInfoResDtoApiResponse,
  CustomerStatusConfigurationFormResDtoApiResponse,
  GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateParams,
  GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeParams,
  MemoReqDto,
  PostAdminReservationChangedParams,
  PostAdminReservationConfirmedParams,
  PostAdminReservationConsultingReservationIdUpdateStatusBody,
  PostAdminReservationWaitingParams,
  ReservationAvailableDateListResDtoApiResponse,
  ReservationAvailableTimeListResDtoApiResponse,
  ReservationChangeReqDto,
  ReservationChangedResDtoPageDtoApiResponse,
  ReservationConfirmedResDtoPageDtoApiResponse,
  ReservationWaitingResDtoPageDtoApiResponse,
  SearchingReqDto,
} from "../../model";
import { customAxios } from "../../mutator/customAxios";
import type { ErrorType } from "../../mutator/customAxios";

/**
 * Get 이지만 이름을 body 로 보내기 위해 Post로 사용.
 * @summary 예약 대기 목록 조회
 */
export const postAdminReservationWaiting = (
  searchingReqDto: SearchingReqDto,
  params?: PostAdminReservationWaitingParams,
) => {
  return customAxios<ReservationWaitingResDtoPageDtoApiResponse>({
    url: `/admin/reservation/waiting`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchingReqDto,
    params,
  });
};

export const getPostAdminReservationWaitingMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationWaiting>>,
    TError,
    { data: SearchingReqDto; params?: PostAdminReservationWaitingParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminReservationWaiting>>,
  TError,
  { data: SearchingReqDto; params?: PostAdminReservationWaitingParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminReservationWaiting>>,
    { data: SearchingReqDto; params?: PostAdminReservationWaitingParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminReservationWaiting(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminReservationWaitingMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminReservationWaiting>>
>;
export type PostAdminReservationWaitingMutationBody = SearchingReqDto;
export type PostAdminReservationWaitingMutationError = ErrorType<unknown>;

/**
 * @summary 예약 대기 목록 조회
 */
export const usePostAdminReservationWaiting = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationWaiting>>,
    TError,
    { data: SearchingReqDto; params?: PostAdminReservationWaitingParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationWaiting>>,
  TError,
  { data: SearchingReqDto; params?: PostAdminReservationWaitingParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationWaitingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get 이지만 이름을 body 로 보내기 위해 Post로 사용. 상담 시작 시간 전/후 선택 필요
 * @summary 예약 확정 목록 조회
 */
export const postAdminReservationConfirmed = (
  searchingReqDto: SearchingReqDto,
  params?: PostAdminReservationConfirmedParams,
) => {
  return customAxios<ReservationConfirmedResDtoPageDtoApiResponse>({
    url: `/admin/reservation/confirmed`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchingReqDto,
    params,
  });
};

export const getPostAdminReservationConfirmedMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
    TError,
    { data: SearchingReqDto; params?: PostAdminReservationConfirmedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
  TError,
  { data: SearchingReqDto; params?: PostAdminReservationConfirmedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
    { data: SearchingReqDto; params?: PostAdminReservationConfirmedParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminReservationConfirmed(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminReservationConfirmedMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminReservationConfirmed>>
>;
export type PostAdminReservationConfirmedMutationBody = SearchingReqDto;
export type PostAdminReservationConfirmedMutationError = ErrorType<unknown>;

/**
 * @summary 예약 확정 목록 조회
 */
export const usePostAdminReservationConfirmed = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
    TError,
    { data: SearchingReqDto; params?: PostAdminReservationConfirmedParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationConfirmed>>,
  TError,
  { data: SearchingReqDto; params?: PostAdminReservationConfirmedParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationConfirmedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get 이지만 이름을 body 로 보내기 위해 Post로 사용.
 * @summary 예약 변경 및 취소 목록 조회
 */
export const postAdminReservationChanged = (
  searchingReqDto: SearchingReqDto,
  params?: PostAdminReservationChangedParams,
) => {
  return customAxios<ReservationChangedResDtoPageDtoApiResponse>({
    url: `/admin/reservation/changed`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: searchingReqDto,
    params,
  });
};

export const getPostAdminReservationChangedMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationChanged>>,
    TError,
    { data: SearchingReqDto; params?: PostAdminReservationChangedParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAdminReservationChanged>>,
  TError,
  { data: SearchingReqDto; params?: PostAdminReservationChangedParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAdminReservationChanged>>,
    { data: SearchingReqDto; params?: PostAdminReservationChangedParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return postAdminReservationChanged(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAdminReservationChangedMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAdminReservationChanged>>
>;
export type PostAdminReservationChangedMutationBody = SearchingReqDto;
export type PostAdminReservationChangedMutationError = ErrorType<unknown>;

/**
 * @summary 예약 변경 및 취소 목록 조회
 */
export const usePostAdminReservationChanged = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAdminReservationChanged>>,
    TError,
    { data: SearchingReqDto; params?: PostAdminReservationChangedParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationChanged>>,
  TError,
  { data: SearchingReqDto; params?: PostAdminReservationChangedParams },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationChangedMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 고객 정보 조회
 */
export const getAdminReservationWaitingReservationConsultingReservationIdInfo =
  (consultingReservationId: number, signal?: AbortSignal) => {
    return customAxios<CrmCustomerInfoResDtoApiResponse>({
      url: `/admin/reservation/waiting/reservation/${consultingReservationId}/info`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationWaitingReservationConsultingReservationIdInfoQueryKey =
  (consultingReservationId: number) => {
    return [
      `/admin/reservation/waiting/reservation/${consultingReservationId}/info`,
    ] as const;
  };

export const getGetAdminReservationWaitingReservationConsultingReservationIdInfoQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationWaitingReservationConsultingReservationIdInfoQueryKey(
        consultingReservationId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
        >
      >
    > = ({ signal }) =>
      getAdminReservationWaitingReservationConsultingReservationIdInfo(
        consultingReservationId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationWaitingReservationConsultingReservationIdInfoQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
      >
    >
  >;
export type GetAdminReservationWaitingReservationConsultingReservationIdInfoQueryError =
  ErrorType<unknown>;

/**
 * @summary 고객 정보 조회
 */
export const useGetAdminReservationWaitingReservationConsultingReservationIdInfo =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationWaitingReservationConsultingReservationIdInfo
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationWaitingReservationConsultingReservationIdInfoQueryOptions(
        consultingReservationId,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 고객 정보 조회
 */
export const getAdminReservationConfirmedReservationConsultingReservationIdInfo =
  (consultingReservationId: number, signal?: AbortSignal) => {
    return customAxios<CrmCustomerInfoResDtoApiResponse>({
      url: `/admin/reservation/confirmed/reservation/${consultingReservationId}/info`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationConfirmedReservationConsultingReservationIdInfoQueryKey =
  (consultingReservationId: number) => {
    return [
      `/admin/reservation/confirmed/reservation/${consultingReservationId}/info`,
    ] as const;
  };

export const getGetAdminReservationConfirmedReservationConsultingReservationIdInfoQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConfirmedReservationConsultingReservationIdInfoQueryKey(
        consultingReservationId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
        >
      >
    > = ({ signal }) =>
      getAdminReservationConfirmedReservationConsultingReservationIdInfo(
        consultingReservationId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConfirmedReservationConsultingReservationIdInfoQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
      >
    >
  >;
export type GetAdminReservationConfirmedReservationConsultingReservationIdInfoQueryError =
  ErrorType<unknown>;

/**
 * @summary 고객 정보 조회
 */
export const useGetAdminReservationConfirmedReservationConsultingReservationIdInfo =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConfirmedReservationConsultingReservationIdInfo
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationConfirmedReservationConsultingReservationIdInfoQueryOptions(
        consultingReservationId,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 고객 정보 조회
 */
export const getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo =
  (consultingReservationHistoryId: number, signal?: AbortSignal) => {
    return customAxios<CrmCustomerInfoResDtoApiResponse>({
      url: `/admin/reservation/changed/reservation-history/${consultingReservationHistoryId}/info`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfoQueryKey =
  (consultingReservationHistoryId: number) => {
    return [
      `/admin/reservation/changed/reservation-history/${consultingReservationHistoryId}/info`,
    ] as const;
  };

export const getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfoQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationHistoryId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfoQueryKey(
        consultingReservationHistoryId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
        >
      >
    > = ({ signal }) =>
      getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo(
        consultingReservationHistoryId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationHistoryId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfoQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
      >
    >
  >;
export type GetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfoQueryError =
  ErrorType<unknown>;

/**
 * @summary 고객 정보 조회
 */
export const useGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationHistoryId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfo
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdInfoQueryOptions(
        consultingReservationHistoryId,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 메모 이력 조회
 */
export const getAdminReservationWaitingReservationConsultingReservationIdMemoHistory =
  (consultingReservationId: number, signal?: AbortSignal) => {
    return customAxios<ConsultantMemoResDtoListApiResponse>({
      url: `/admin/reservation/waiting/reservation/${consultingReservationId}/memo-history`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationWaitingReservationConsultingReservationIdMemoHistoryQueryKey =
  (consultingReservationId: number) => {
    return [
      `/admin/reservation/waiting/reservation/${consultingReservationId}/memo-history`,
    ] as const;
  };

export const getGetAdminReservationWaitingReservationConsultingReservationIdMemoHistoryQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationWaitingReservationConsultingReservationIdMemoHistoryQueryKey(
        consultingReservationId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
        >
      >
    > = ({ signal }) =>
      getAdminReservationWaitingReservationConsultingReservationIdMemoHistory(
        consultingReservationId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationWaitingReservationConsultingReservationIdMemoHistoryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
      >
    >
  >;
export type GetAdminReservationWaitingReservationConsultingReservationIdMemoHistoryQueryError =
  ErrorType<unknown>;

/**
 * @summary 메모 이력 조회
 */
export const useGetAdminReservationWaitingReservationConsultingReservationIdMemoHistory =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationWaitingReservationConsultingReservationIdMemoHistory
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationWaitingReservationConsultingReservationIdMemoHistoryQueryOptions(
        consultingReservationId,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 메모 이력 조회
 */
export const getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory =
  (consultingReservationId: number, signal?: AbortSignal) => {
    return customAxios<ConsultantMemoResDtoListApiResponse>({
      url: `/admin/reservation/confirmed/reservation/${consultingReservationId}/memo-history`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationConfirmedReservationConsultingReservationIdMemoHistoryQueryKey =
  (consultingReservationId: number) => {
    return [
      `/admin/reservation/confirmed/reservation/${consultingReservationId}/memo-history`,
    ] as const;
  };

export const getGetAdminReservationConfirmedReservationConsultingReservationIdMemoHistoryQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConfirmedReservationConsultingReservationIdMemoHistoryQueryKey(
        consultingReservationId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
        >
      >
    > = ({ signal }) =>
      getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory(
        consultingReservationId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConfirmedReservationConsultingReservationIdMemoHistoryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
      >
    >
  >;
export type GetAdminReservationConfirmedReservationConsultingReservationIdMemoHistoryQueryError =
  ErrorType<unknown>;

/**
 * @summary 메모 이력 조회
 */
export const useGetAdminReservationConfirmedReservationConsultingReservationIdMemoHistory =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConfirmedReservationConsultingReservationIdMemoHistory
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationConfirmedReservationConsultingReservationIdMemoHistoryQueryOptions(
        consultingReservationId,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 메모 이력 조회
 */
export const getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory =
  (consultingReservationHistoryId: number, signal?: AbortSignal) => {
    return customAxios<ConsultantMemoResDtoListApiResponse>({
      url: `/admin/reservation/changed/reservation-history/${consultingReservationHistoryId}/memo-history`,
      method: "GET",
      signal,
    });
  };

export const getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistoryQueryKey =
  (consultingReservationHistoryId: number) => {
    return [
      `/admin/reservation/changed/reservation-history/${consultingReservationHistoryId}/memo-history`,
    ] as const;
  };

export const getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistoryQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationHistoryId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistoryQueryKey(
        consultingReservationHistoryId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
        >
      >
    > = ({ signal }) =>
      getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory(
        consultingReservationHistoryId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationHistoryId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistoryQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
      >
    >
  >;
export type GetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistoryQueryError =
  ErrorType<unknown>;

/**
 * @summary 메모 이력 조회
 */
export const useGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationHistoryId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistory
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationChangedReservationHistoryConsultingReservationHistoryIdMemoHistoryQueryOptions(
        consultingReservationHistoryId,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 메모 저장
 */
export const postAdminReservationWaitingReservationConsultingReservationIdSaveMemo =
  (consultingReservationId: number, memoReqDto: MemoReqDto) => {
    return customAxios<ApiResponse>({
      url: `/admin/reservation/waiting/reservation/${consultingReservationId}/save-memo`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: memoReqDto,
    });
  };

export const getPostAdminReservationWaitingReservationConsultingReservationIdSaveMemoMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
        >
      >,
      TError,
      { consultingReservationId: number; data: MemoReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
      >
    >,
    TError,
    { consultingReservationId: number; data: MemoReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
        >
      >,
      { consultingReservationId: number; data: MemoReqDto }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return postAdminReservationWaitingReservationConsultingReservationIdSaveMemo(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminReservationWaitingReservationConsultingReservationIdSaveMemoMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
      >
    >
  >;
export type PostAdminReservationWaitingReservationConsultingReservationIdSaveMemoMutationBody =
  MemoReqDto;
export type PostAdminReservationWaitingReservationConsultingReservationIdSaveMemoMutationError =
  ErrorType<unknown>;

/**
 * @summary 메모 저장
 */
export const usePostAdminReservationWaitingReservationConsultingReservationIdSaveMemo =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
        >
      >,
      TError,
      { consultingReservationId: number; data: MemoReqDto },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof postAdminReservationWaitingReservationConsultingReservationIdSaveMemo
      >
    >,
    TError,
    { consultingReservationId: number; data: MemoReqDto },
    TContext
  > => {
    const mutationOptions =
      getPostAdminReservationWaitingReservationConsultingReservationIdSaveMemoMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };
/**
 * @summary 메모 저장
 */
export const postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo =
  (consultingReservationId: number, memoReqDto: MemoReqDto) => {
    return customAxios<ApiResponse>({
      url: `/admin/reservation/confirmed/reservation/${consultingReservationId}/save-memo`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: memoReqDto,
    });
  };

export const getPostAdminReservationConfirmedReservationConsultingReservationIdSaveMemoMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo
        >
      >,
      TError,
      { consultingReservationId: number; data: MemoReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo
      >
    >,
    TError,
    { consultingReservationId: number; data: MemoReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo
        >
      >,
      { consultingReservationId: number; data: MemoReqDto }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminReservationConfirmedReservationConsultingReservationIdSaveMemoMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo
      >
    >
  >;
export type PostAdminReservationConfirmedReservationConsultingReservationIdSaveMemoMutationBody =
  MemoReqDto;
export type PostAdminReservationConfirmedReservationConsultingReservationIdSaveMemoMutationError =
  ErrorType<unknown>;

/**
 * @summary 메모 저장
 */
export const usePostAdminReservationConfirmedReservationConsultingReservationIdSaveMemo =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo
        >
      >,
      TError,
      { consultingReservationId: number; data: MemoReqDto },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof postAdminReservationConfirmedReservationConsultingReservationIdSaveMemo
      >
    >,
    TError,
    { consultingReservationId: number; data: MemoReqDto },
    TContext
  > => {
    const mutationOptions =
      getPostAdminReservationConfirmedReservationConsultingReservationIdSaveMemoMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };
/**
 * @summary 메모 저장
 */
export const postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo =
  (consultingReservationHistoryId: number, memoReqDto: MemoReqDto) => {
    return customAxios<ApiResponse>({
      url: `/admin/reservation/changed/reservation-history/${consultingReservationHistoryId}/save-memo`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: memoReqDto,
    });
  };

export const getPostAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemoMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
        >
      >,
      TError,
      { consultingReservationHistoryId: number; data: MemoReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
      >
    >,
    TError,
    { consultingReservationHistoryId: number; data: MemoReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
        >
      >,
      { consultingReservationHistoryId: number; data: MemoReqDto }
    > = (props) => {
      const { consultingReservationHistoryId, data } = props ?? {};

      return postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo(
        consultingReservationHistoryId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemoMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
      >
    >
  >;
export type PostAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemoMutationBody =
  MemoReqDto;
export type PostAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemoMutationError =
  ErrorType<unknown>;

/**
 * @summary 메모 저장
 */
export const usePostAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
        >
      >,
      TError,
      { consultingReservationHistoryId: number; data: MemoReqDto },
      TContext
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof postAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemo
      >
    >,
    TError,
    { consultingReservationHistoryId: number; data: MemoReqDto },
    TContext
  > => {
    const mutationOptions =
      getPostAdminReservationChangedReservationHistoryConsultingReservationHistoryIdSaveMemoMutationOptions(
        options,
      );

    return useMutation(mutationOptions);
  };
/**
 * @summary 상담 연락처 선택지
 */
export const getAdminReservationContactInfo = (signal?: AbortSignal) => {
  return customAxios<ConsultingContactInfoResDtoConsultingOptionsResDtoApiResponse>(
    { url: `/admin/reservation/contact-info`, method: "GET", signal },
  );
};

export const getGetAdminReservationContactInfoQueryKey = () => {
  return [`/admin/reservation/contact-info`] as const;
};

export const getGetAdminReservationContactInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminReservationContactInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationContactInfo>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAdminReservationContactInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminReservationContactInfo>>
  > = ({ signal }) => getAdminReservationContactInfo(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminReservationContactInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminReservationContactInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminReservationContactInfo>>
>;
export type GetAdminReservationContactInfoQueryError = ErrorType<unknown>;

/**
 * @summary 상담 연락처 선택지
 */
export const useGetAdminReservationContactInfo = <
  TData = Awaited<ReturnType<typeof getAdminReservationContactInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationContactInfo>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAdminReservationContactInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 유선 상담일 경우 해당 api 호출 안함
 * @summary 상담 장소 선택지
 */
export const getAdminReservationConsultingAddress = (signal?: AbortSignal) => {
  return customAxios<ConsultingAddressResDtoConsultingOptionsResDtoApiResponse>(
    { url: `/admin/reservation/consulting-address`, method: "GET", signal },
  );
};

export const getGetAdminReservationConsultingAddressQueryKey = () => {
  return [`/admin/reservation/consulting-address`] as const;
};

export const getGetAdminReservationConsultingAddressQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAdminReservationConsultingAddressQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>
  > = ({ signal }) => getAdminReservationConsultingAddress(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminReservationConsultingAddressQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>
>;
export type GetAdminReservationConsultingAddressQueryError = ErrorType<unknown>;

/**
 * @summary 상담 장소 선택지
 */
export const useGetAdminReservationConsultingAddress = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultingAddress>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminReservationConsultingAddressQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 상담 승인
 */
export const patchAdminReservationConsultingReservationIdAccept = (
  consultingReservationId: number,
  consultingOptionsReqDto: ConsultingOptionsReqDto,
) => {
  return customAxios<ApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/accept`,
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    data: consultingOptionsReqDto,
  });
};

export const getPatchAdminReservationConsultingReservationIdAcceptMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
      >,
      TError,
      { consultingReservationId: number; data: ConsultingOptionsReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
    >,
    TError,
    { consultingReservationId: number; data: ConsultingOptionsReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
      >,
      { consultingReservationId: number; data: ConsultingOptionsReqDto }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return patchAdminReservationConsultingReservationIdAccept(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchAdminReservationConsultingReservationIdAcceptMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
    >
  >;
export type PatchAdminReservationConsultingReservationIdAcceptMutationBody =
  ConsultingOptionsReqDto;
export type PatchAdminReservationConsultingReservationIdAcceptMutationError =
  ErrorType<unknown>;

/**
 * @summary 상담 승인
 */
export const usePatchAdminReservationConsultingReservationIdAccept = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
    >,
    TError,
    { consultingReservationId: number; data: ConsultingOptionsReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof patchAdminReservationConsultingReservationIdAccept>
  >,
  TError,
  { consultingReservationId: number; data: ConsultingOptionsReqDto },
  TContext
> => {
  const mutationOptions =
    getPatchAdminReservationConsultingReservationIdAcceptMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * 상담 변경 시 사용
 * @summary 상담사 선택지
 */
export const getAdminReservationConsultantInfo = (signal?: AbortSignal) => {
  return customAxios<ConsultantInfoResDtoConsultingOptionsResDtoApiResponse>({
    url: `/admin/reservation/consultant-info`,
    method: "GET",
    signal,
  });
};

export const getGetAdminReservationConsultantInfoQueryKey = () => {
  return [`/admin/reservation/consultant-info`] as const;
};

export const getGetAdminReservationConsultantInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
      TError,
      TData
    >
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAdminReservationConsultantInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>
  > = ({ signal }) => getAdminReservationConsultantInfo(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAdminReservationConsultantInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>
>;
export type GetAdminReservationConsultantInfoQueryError = ErrorType<unknown>;

/**
 * @summary 상담사 선택지
 */
export const useGetAdminReservationConsultantInfo = <
  TData = Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof getAdminReservationConsultantInfo>>,
      TError,
      TData
    >
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminReservationConsultantInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 예약 변경
 */
export const postAdminReservationConsultingReservationIdChange = (
  consultingReservationId: number,
  reservationChangeReqDto: ReservationChangeReqDto,
) => {
  return customAxios<ApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/change`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: reservationChangeReqDto,
  });
};

export const getPostAdminReservationConsultingReservationIdChangeMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postAdminReservationConsultingReservationIdChange>
      >,
      TError,
      { consultingReservationId: number; data: ReservationChangeReqDto },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdChange>
    >,
    TError,
    { consultingReservationId: number; data: ReservationChangeReqDto },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postAdminReservationConsultingReservationIdChange>
      >,
      { consultingReservationId: number; data: ReservationChangeReqDto }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return postAdminReservationConsultingReservationIdChange(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminReservationConsultingReservationIdChangeMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdChange>
    >
  >;
export type PostAdminReservationConsultingReservationIdChangeMutationBody =
  ReservationChangeReqDto;
export type PostAdminReservationConsultingReservationIdChangeMutationError =
  ErrorType<unknown>;

/**
 * @summary 예약 변경
 */
export const usePostAdminReservationConsultingReservationIdChange = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdChange>
    >,
    TError,
    { consultingReservationId: number; data: ReservationChangeReqDto },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof postAdminReservationConsultingReservationIdChange>>,
  TError,
  { consultingReservationId: number; data: ReservationChangeReqDto },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationConsultingReservationIdChangeMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * @summary 예약 취소
 */
export const deleteAdminReservationConsultingReservationIdCancel = (
  consultingReservationId: number,
) => {
  return customAxios<ApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/cancel`,
    method: "DELETE",
  });
};

export const getDeleteAdminReservationConsultingReservationIdCancelMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof deleteAdminReservationConsultingReservationIdCancel>
      >,
      TError,
      { consultingReservationId: number },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteAdminReservationConsultingReservationIdCancel>
    >,
    TError,
    { consultingReservationId: number },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof deleteAdminReservationConsultingReservationIdCancel>
      >,
      { consultingReservationId: number }
    > = (props) => {
      const { consultingReservationId } = props ?? {};

      return deleteAdminReservationConsultingReservationIdCancel(
        consultingReservationId,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteAdminReservationConsultingReservationIdCancelMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof deleteAdminReservationConsultingReservationIdCancel>
    >
  >;

export type DeleteAdminReservationConsultingReservationIdCancelMutationError =
  ErrorType<unknown>;

/**
 * @summary 예약 취소
 */
export const useDeleteAdminReservationConsultingReservationIdCancel = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof deleteAdminReservationConsultingReservationIdCancel>
    >,
    TError,
    { consultingReservationId: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteAdminReservationConsultingReservationIdCancel>
  >,
  TError,
  { consultingReservationId: number },
  TContext
> => {
  const mutationOptions =
    getDeleteAdminReservationConsultingReservationIdCancelMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * @summary 상담 예약 가능 날짜
 */
export const getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate =
  (
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateParams,
    signal?: AbortSignal,
  ) => {
    return customAxios<ReservationAvailableDateListResDtoApiResponse>({
      url: `/admin/reservation/consultant/${consultantId}/date/${targetDate}/available-date`,
      method: "GET",
      params,
      signal,
    });
  };

export const getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateQueryKey =
  (
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateParams,
  ) => {
    return [
      `/admin/reservation/consultant/${consultantId}/date/${targetDate}/available-date`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateQueryKey(
        consultantId,
        targetDate,
        params,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
        >
      >
    > = ({ signal }) =>
      getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate(
        consultantId,
        targetDate,
        params,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(consultantId && targetDate),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
      >
    >
  >;
export type GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 예약 가능 날짜
 */
export const useGetAdminReservationConsultantConsultantIdDateTargetDateAvailableDate =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableDate
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableDateQueryOptions(
        consultantId,
        targetDate,
        params,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 상담 예약 가능 시간
 */
export const getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime =
  (
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeParams,
    signal?: AbortSignal,
  ) => {
    return customAxios<ReservationAvailableTimeListResDtoApiResponse>({
      url: `/admin/reservation/consultant/${consultantId}/date/${targetDate}/available-time`,
      method: "GET",
      params,
      signal,
    });
  };

export const getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeQueryKey =
  (
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeParams,
  ) => {
    return [
      `/admin/reservation/consultant/${consultantId}/date/${targetDate}/available-time`,
      ...(params ? [params] : []),
    ] as const;
  };

export const getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeQueryKey(
        consultantId,
        targetDate,
        params,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
        >
      >
    > = ({ signal }) =>
      getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime(
        consultantId,
        targetDate,
        params,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(consultantId && targetDate),
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
      >
    >
  >;
export type GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeQueryError =
  ErrorType<unknown>;

/**
 * @summary 상담 예약 가능 시간
 */
export const useGetAdminReservationConsultantConsultantIdDateTargetDateAvailableTime =
  <
    TData = Awaited<
      ReturnType<
        typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
      >
    >,
    TError = ErrorType<unknown>,
  >(
    consultantId: string,
    targetDate: string,
    params?: GetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeParams,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultantConsultantIdDateTargetDateAvailableTime
            >
          >,
          TError,
          TData
        >
      >;
    },
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      getGetAdminReservationConsultantConsultantIdDateTargetDateAvailableTimeQueryOptions(
        consultantId,
        targetDate,
        params,
        options,
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };

/**
 * @summary 고객 상태 설정 조회
 */
export const getAdminReservationConsultingReservationIdUpdateStatus = (
  consultingReservationId: number,
  signal?: AbortSignal,
) => {
  return customAxios<CustomerStatusConfigurationFormResDtoApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/update-status`,
    method: "GET",
    signal,
  });
};

export const getGetAdminReservationConsultingReservationIdUpdateStatusQueryKey =
  (consultingReservationId: number) => {
    return [
      `/admin/reservation/${consultingReservationId}/update-status`,
    ] as const;
  };

export const getGetAdminReservationConsultingReservationIdUpdateStatusQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getAdminReservationConsultingReservationIdUpdateStatus>
    >,
    TError = ErrorType<unknown>,
  >(
    consultingReservationId: number,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getAdminReservationConsultingReservationIdUpdateStatus
            >
          >,
          TError,
          TData
        >
      >;
    },
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetAdminReservationConsultingReservationIdUpdateStatusQueryKey(
        consultingReservationId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultingReservationIdUpdateStatus
        >
      >
    > = ({ signal }) =>
      getAdminReservationConsultingReservationIdUpdateStatus(
        consultingReservationId,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!consultingReservationId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof getAdminReservationConsultingReservationIdUpdateStatus
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetAdminReservationConsultingReservationIdUpdateStatusQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getAdminReservationConsultingReservationIdUpdateStatus>
    >
  >;
export type GetAdminReservationConsultingReservationIdUpdateStatusQueryError =
  ErrorType<unknown>;

/**
 * @summary 고객 상태 설정 조회
 */
export const useGetAdminReservationConsultingReservationIdUpdateStatus = <
  TData = Awaited<
    ReturnType<typeof getAdminReservationConsultingReservationIdUpdateStatus>
  >,
  TError = ErrorType<unknown>,
>(
  consultingReservationId: number,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getAdminReservationConsultingReservationIdUpdateStatus
          >
        >,
        TError,
        TData
      >
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetAdminReservationConsultingReservationIdUpdateStatusQueryOptions(
      consultingReservationId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 고객 상태 설정 완료
 */
export const postAdminReservationConsultingReservationIdUpdateStatus = (
  consultingReservationId: number,
  postAdminReservationConsultingReservationIdUpdateStatusBody: PostAdminReservationConsultingReservationIdUpdateStatusBody,
) => {
  const formData = new FormData();
  if (
    postAdminReservationConsultingReservationIdUpdateStatusBody.CmaHoldingStatusCode !==
    undefined
  ) {
    formData.append(
      "CmaHoldingStatusCode",
      postAdminReservationConsultingReservationIdUpdateStatusBody.CmaHoldingStatusCode,
    );
  }
  if (
    postAdminReservationConsultingReservationIdUpdateStatusBody.PensionHoldingStatusCode !==
    undefined
  ) {
    formData.append(
      "PensionHoldingStatusCode",
      postAdminReservationConsultingReservationIdUpdateStatusBody.PensionHoldingStatusCode,
    );
  }
  if (
    postAdminReservationConsultingReservationIdUpdateStatusBody.IrpHoldingStatusCode !==
    undefined
  ) {
    formData.append(
      "IrpHoldingStatusCode",
      postAdminReservationConsultingReservationIdUpdateStatusBody.IrpHoldingStatusCode,
    );
  }
  if (
    postAdminReservationConsultingReservationIdUpdateStatusBody.IsaHoldingStatusCode !==
    undefined
  ) {
    formData.append(
      "IsaHoldingStatusCode",
      postAdminReservationConsultingReservationIdUpdateStatusBody.IsaHoldingStatusCode,
    );
  }
  if (
    postAdminReservationConsultingReservationIdUpdateStatusBody.PersonalHoldingStatusCode !==
    undefined
  ) {
    formData.append(
      "PersonalHoldingStatusCode",
      postAdminReservationConsultingReservationIdUpdateStatusBody.PersonalHoldingStatusCode,
    );
  }
  if (
    postAdminReservationConsultingReservationIdUpdateStatusBody.CorporateHoldingStatusCode !==
    undefined
  ) {
    formData.append(
      "CorporateHoldingStatusCode",
      postAdminReservationConsultingReservationIdUpdateStatusBody.CorporateHoldingStatusCode,
    );
  }

  return customAxios<ApiResponse>({
    url: `/admin/reservation/${consultingReservationId}/update-status`,
    method: "POST",
    headers: { "Content-Type": "multipart/form-data" },
    data: formData,
  });
};

export const getPostAdminReservationConsultingReservationIdUpdateStatusMutationOptions =
  <TError = ErrorType<unknown>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof postAdminReservationConsultingReservationIdUpdateStatus
        >
      >,
      TError,
      {
        consultingReservationId: number;
        data: PostAdminReservationConsultingReservationIdUpdateStatusBody;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdUpdateStatus>
    >,
    TError,
    {
      consultingReservationId: number;
      data: PostAdminReservationConsultingReservationIdUpdateStatusBody;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof postAdminReservationConsultingReservationIdUpdateStatus
        >
      >,
      {
        consultingReservationId: number;
        data: PostAdminReservationConsultingReservationIdUpdateStatusBody;
      }
    > = (props) => {
      const { consultingReservationId, data } = props ?? {};

      return postAdminReservationConsultingReservationIdUpdateStatus(
        consultingReservationId,
        data,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostAdminReservationConsultingReservationIdUpdateStatusMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdUpdateStatus>
    >
  >;
export type PostAdminReservationConsultingReservationIdUpdateStatusMutationBody =
  PostAdminReservationConsultingReservationIdUpdateStatusBody;
export type PostAdminReservationConsultingReservationIdUpdateStatusMutationError =
  ErrorType<unknown>;

/**
 * @summary 고객 상태 설정 완료
 */
export const usePostAdminReservationConsultingReservationIdUpdateStatus = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postAdminReservationConsultingReservationIdUpdateStatus>
    >,
    TError,
    {
      consultingReservationId: number;
      data: PostAdminReservationConsultingReservationIdUpdateStatusBody;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof postAdminReservationConsultingReservationIdUpdateStatus>
  >,
  TError,
  {
    consultingReservationId: number;
    data: PostAdminReservationConsultingReservationIdUpdateStatusBody;
  },
  TContext
> => {
  const mutationOptions =
    getPostAdminReservationConsultingReservationIdUpdateStatusMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
