export function combineDateAndTime(date: Date, timeStr: string): Date {
  let [hours, minutes] = timeStr.split(":").map(Number);

  // 오전/오후 구분
  // 오후: 12:00-7:00 -> 12:00-19:00
  if (hours >= 1 && hours <= 7) {
    hours += 12; // 오후 1:00-7:00 -> 13:00-19:00
  } else if (hours === 12) {
    hours = 12; // 12:00은 그대로 12:00
  }
  // 오전: 9:00-11:30 -> 그대로 사용

  console.log("hours", hours);
  console.log("minutes", minutes);

  const newDate = new Date(date);
  newDate.setHours(hours + 9, minutes, 0, 0); // 9시간을 빼서 한국 시간으로 변환

  return newDate;
}
