export function decombineDateTime(date: Date): {
  date: Date;
  time: string;
} {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Create a new date object with just the date portion
  const dateOnly = new Date(date);
  dateOnly.setHours(0, 0, 0, 0);

  // Convert 24-hour format to 12-hour format
  let displayHours = hours;
  if (hours > 12) {
    displayHours = hours - 12;
  } else if (hours === 0) {
    displayHours = 12;
  }

  // Format time string
  const timeStr = `${displayHours}:${minutes.toString().padStart(2, "0")}`;

  return {
    date: dateOnly,
    time: timeStr,
  };
}
