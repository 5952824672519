import { usePostAdminUserMfa } from "@/api";
import { CognitoMfaTokenModel } from "@/api";
import ErrorMessage from "@/components/ErrorMessage";
import LoadingOverlay from "@/components/LoadingOverlay";
import Space from "@/components/Space";
import styled from "@/theme";
import getErrorMessageFromRes from "@/utils/getErrorMessageFromRes";
import { SafetyOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputRef } from "antd";
import { useEffect, useRef } from "react";

const TOKEN_LENGTH = 6;

type MfaFormProps = {
  email: string;
  session: string;
  onSuccess?: (accessToken: string, refreshToken: string) => void;
};
type MfaFormState = Omit<CognitoMfaTokenModel, "email" | "session">;
function MfaForm({ session, email, onSuccess }: MfaFormProps) {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (session && email) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, email]);

  const { mutateAsync: mfa, isPending, error } = usePostAdminUserMfa();

  const onFinish = async (data: MfaFormState) => {
    const res = await mfa({ data: { ...data, session, email } });
    if (!res.result) {
      return;
    }

    const { accessToken, refreshToken } = res.result;
    if (!accessToken || !refreshToken) {
      return;
    }

    onSuccess?.(accessToken, refreshToken);
  };

  const errorMessage = getErrorMessageFromRes(error);

  return (
    <Form name="normal_login" className="login-form" onFinish={onFinish}>
      <Form.Item
        name="mfaToken"
        noStyle
        rules={[
          {
            required: true,
            message: "인증번호를 입력해 주세요.",
            min: TOKEN_LENGTH,
            max: TOKEN_LENGTH,
          },
        ]}
      >
        <Input
          prefix={<SafetyOutlined className="site-form-item-icon" />}
          placeholder="SMS 인증번호"
          maxLength={TOKEN_LENGTH}
          ref={inputRef}
        />
      </Form.Item>

      <Space h={20} />

      <Form.Item noStyle>
        <LoginButton
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          인증하기
        </LoginButton>
      </Form.Item>

      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {isPending && <LoadingOverlay />}
    </Form>
  );
}

const LoginButton = styled(Button)`
  width: 100%;
`;

export default MfaForm;
